<script>
import Layout from "../../layouts/main";
import BalanceLayout from "../../layouts/balanceLayout";
import PageHeader from "@/components/Page-header";
import ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import errorHandler from '@/helpers/errorHandler'
import moment from 'moment';
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page: {
    title: "Balance list",
  },
  head() {
    return {
      title: `Balance-Qodes`,
    };
  },
  components: {
    Layout,
    BalanceLayout,
    PageHeader,
    ConfirmActionDialog,
    ExportExcel
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    await this.$store.dispatch("balance/getAllCenterList").then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      })
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    centers() {
      return this.$store.getters["balance/allCenterList"];
    },
    rows() {
      return this.centers.length;
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("balance/deleteCenter",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
            this.$bvModal.show('deleteRecord')
             this.recordId = id
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // async downloadQrcodes(time_stamp){
    //     await this.$store.dispatch("qrcode/downloadQrcodes",time_stamp).then(response => {
    //         const href = URL.createObjectURL(response.data);
    //         // create "a" HTML element with href to file & click
    //         const link = document.createElement('a');
    //         link.href = href;
    //         link.setAttribute('download', 'file.zip'); //or any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //         // clean up "a" element & remove ObjectURL
    //         document.body.removeChild(link);
    //         URL.revokeObjectURL(href);
    //     });
    // }
  },
  filters:{
    formatDate(value){
        if(value){
            return moment(String(value)).format('hh:mm A DD/MM/YYYY')
        }
    }
  },
  data: () => ({
    baseUrl: null,    totalRows:0,
    recordId: null,
    title: "Balance-centers List",
    items: [
      {
        text: "Work Center",
        href: "/"
      },
      {
        text: "Balance-codes",
        href: "/balance-codes"
      },
      {
        text: 'centers',
        ref: false
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "time_stamp",
    sortDesc: false,
    filterOn: ["time_stamp","number_of_balance_code"],
    fields: [
      {
        key: "name",
        label: "Name",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
  <Layout>
    <BalanceLayout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <router-link to="/balance-codes/centers/create" class="d-md-inline d-flex justify-content-center">
                    <b-button variant="success" class="btn-sm mr-2">
                      <i class="mdi mdi-plus"></i>
                        Add center
                    </b-button>
                  </router-link>
                  <export-excel :jsonData="centers" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="centers"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            <template v-slot:cell(time_stamp)="data">
              {{ data.item.time_stamp | formatDate }}
            </template>
            <template v-slot:cell(action)="data">
                  <b-button variant="danger" pill
                    v-on:click="showDeleteModal(data.item.id)"
                    ><i
                        class="mdi mdi-delete mr-1  vertical-middle iconGray"
                    ></i
                    >Delete
                  </b-button>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BalanceLayout>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
  </Layout>
</template>
